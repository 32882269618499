import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { topicImageWrapper, topicImage } from './topic.module.scss';

const TopicImage = ({ image, alt }) => {
    const imageData = getImage(image);

    return <GatsbyImage image={imageData} className={topicImageWrapper} imgClassName={topicImage} alt={alt} loading={'eager'} />
}

export default TopicImage;
