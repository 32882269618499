import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { topicList, topicListItem } from './topics.module.scss';
import SEO from '../components/seo';
import TopicCard from '../components/topic-card';

const TopicsPage = ({ data }) => {
    const topics = data.allTopicsJson.nodes;

    return (
        <>
            <SEO title={"Topics | Web Performance Tips"} description={"Find Web Performance Tips by Topic"} />
            <Layout pageTitle="Topics">
                <ul className={topicList}>
                    {
                        topics.map(topic => {
                            return (
                                <li key={topic.id} className={topicListItem}>
                                    <TopicCard topic={topic} />
                                </li>
                            );
                        })
                    }
                </ul>
            </Layout>
        </>
    )
}

export const query = graphql`
    query {
        allTopicsJson(sort: { fields: displayName, order: ASC }) {
            nodes {
                displayName
                topicId
                id
                image {
                    childImageSharp {
                        gatsbyImageData(placeholder: TRACED_SVG)
                    }
                }
            }
        }
    }
`

export default TopicsPage;

