import * as React from 'react';
import { Link } from 'gatsby';

import { cardItem, cardLink } from './card.module.scss';
import {
    topicItem,
    topicImageContainer,
    topicTitle,
} from './topic.module.scss';
import TopicImage from './topic-card-image';

const TopicCard = ({ topic }) => {
    return (
        <Link to={`/topic/${topic.topicId}`} className={cardLink}>
            <div className={`${cardItem} ${topicItem}`}>
                <div className={topicImageContainer}>
                    <h2 className={topicTitle}>{topic.displayName}</h2>
                    <TopicImage image={topic.image} alt={topic.displayName} />
                </div>
            </div>
        </Link>
    );
};

export default TopicCard;
